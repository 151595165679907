
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

@import '~@/assets/scss/blocks/control-settings.scss';

.setting-item__child-settings {
  display: flex;
  flex-direction: column;
}
